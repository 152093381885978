export default {
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/modules/settings/layouts/SettingsLayout.vue'),
    children: [
        {
            path: 'users',
            name: 'Usuarios',
            component: () => import(/* webpackChunkName: "users" */ '@/modules/settings/views/UserView.vue'),
        },
        {
            path: 'roles',
            name: 'Roles',
            component: () => import(/* webpackChunkName: "roles" */ '@/modules/settings/views/RoleView.vue'),
        },
        {
            path: 'permissions',
            name: 'Permisos',
            component: () => import(/* webpackChunkName: "permissions" */ '@/modules/settings/views/PermissionView.vue'),
        },
        {
            path: '',
            redirect: { name: 'users' }
        }
    ]
}