import ServiceAPI from '../utils/axios.js';

const url = '/maestro/alumnos';

export default {
    list() {
        return ServiceAPI.post(`${url}/list`, {});
    },
    get_data() {
        return ServiceAPI.post(`${url}/get-data`, {});
    },    
}