import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/v-login/Signin.vue";
import settingsRouter from '@/modules/settings/router';
import paymentRouter from '@/modules/payment-code/router';
import studentRouter from '@/modules/student/router';
import reportRouter from '@/modules/reports/router';
import syncRouter from '@/modules/deposits/router';

import isAuthenticatedGuard from "./auth-guard";
import roleBasedAuthGuard from "./role-guard";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/signin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    beforeEnter: [ isAuthenticatedGuard ],
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/settings",
    meta: { roles: ['Sistemas', 'Administrador'] },
    beforeEnter: [ isAuthenticatedGuard, roleBasedAuthGuard ],
    ...settingsRouter
  },
  {
    path: "/maestro",
    meta: { roles: ['Sistemas', 'Administrador'] },
    beforeEnter: [ isAuthenticatedGuard, roleBasedAuthGuard ],
    ...paymentRouter
  },
  {
    path: "/alumno",
    meta: { roles: ['Sistemas', 'Administrador'] },
    beforeEnter: [ isAuthenticatedGuard, roleBasedAuthGuard  ],
    ...studentRouter
  },
  {
    path: "/reporte",
    meta: { roles: ['Sistemas', 'Administrador'] },
    beforeEnter: [ isAuthenticatedGuard, roleBasedAuthGuard  ],
    ...reportRouter
  },
  {
    path: "/depositos",
    meta: { roles: ['Sistemas', 'Administrador'] },
    beforeEnter: [ isAuthenticatedGuard, roleBasedAuthGuard  ],
    ...syncRouter
  },
  
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: { name: 'Dashboard' }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

// // Guard Global - Sincro
// const canAccess = () => {
//   return new Promise( resolve => {

//   })
// }

// router.beforeEach( async( to, from , next ) => {
//   const authorized = await canAccess()
//   authorized
//     ? next()
//     : next({ name: 'Dashboard' })
// })

export default router;
