export default {
    name: 'Codigo Pago',
    component: () => import('@/modules/payment-code/layouts/PaymentLayout.vue'),
    children: [
        {
            path: 'codigo-pago',
            name: 'Codigo Pago',
            component: () => import('@/modules/payment-code/views/PaymentCode.vue'),
        },
        {
            path: 'programas',
            name: 'Programas',
            component: () => import('@/modules/payment-code/views/Courses.vue'),
        },
        {
            path: 'students',
            name: 'Alumnos Registrados',
            component: () => import('@/modules/payment-code/views/Students.vue'),
        }
    ]
    
}