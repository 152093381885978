export default {
    name: 'Sincronizar',
    component: () => import('@/modules/deposits/layouts/SyncLayout.vue'),
    children: [
        {
            path: 'sincronizar',
            name: 'Sincronizar',
            component: () => import('@/modules/deposits/views/Sync.vue'),
        },
        {
            path: 'gestionar',
            name: 'Gestionar',
            component: () => import('@/modules/deposits/views/Manage.vue'),
        },
        {
            path: 'matricula',
            name: 'Matricula',
            component: () => import('@/modules/deposits/views/Enroll.vue'),
        },
    ]
    
}