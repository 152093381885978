import Swal from "@/plugins/sweetalert2.js";

// Función para obtener el rol del usuario desde algún lugar (por ejemplo, desde el token de autenticación)
const getUserRole = async () => {
    const role = localStorage.getItem('userRole');
    console.log("Los roles desde el guard son: ", role)
    return role; // Retorna el rol del usuario
}

// Guardia de autenticación basado en roles
const roleBasedAuthGuard = async (to, from, next) => {
    try {
        const userRole = await getUserRole(); // Obtener el rol del usuario
        if (userRole) {
            const userRolesArray = userRole.split(','); // Convertir la cadena de roles en un array
            // Verificar si el usuario tiene permiso para acceder a la ruta
            if (to.meta.roles && to.meta.roles.some(role => userRolesArray.includes(role))) {
                next(); // Permitir acceso a la ruta
            } else {
                Swal.error('No tienes permiso para acceder a esta ruta');
                console.log("No tienes permiso para acceder a esta ruta");
                // Redirigir a una ruta de acceso denegado u otra ruta apropiada
                next({ name: 'Dashboard' }); 
            }
        } else {
            console.log("Usuario no autenticado");
            // Redirigir al usuario a la página de inicio de sesión u otra ruta de acceso denegado
            next({ name: '/' }); 
        }
    } catch (error) {
        console.error("Error en el guardia:", error);
        // Manejar el error redirigiendo a una página de error u otra ruta apropiada
        next({ name: '/' }); 
    }
}

export default roleBasedAuthGuard;
