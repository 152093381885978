export default {
    name: 'Alumnos',
    component: () => import('@/modules/student/layouts/StudentLayout.vue'),
    children: [
        {
            path: 'perfil',
            name: 'Perfil',
            component: () => import('@/modules/student/views/Student.vue'),
        }
    ]
}