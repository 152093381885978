import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  customClass: {
    htmlContainer: 'text-sm',
    popup: 'rounded-2 py-1'
  },
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

export default {
  success(title) {
    Toast.fire({
      icon: "success",
      html: title,
    });
  },

  error(title) {
    Toast.fire({
      icon: "error",
      html: title,
    });
  },

  info(title) {
    Toast.fire({
      icon: "info",
      html: title,
    });
  },

  warning(title) {
    Toast.fire({
      icon: "warning",
      html: title,
    });
  },

  loading(title) {
    Swal.fire({
      html: title,
      //timer: 2000,
      width: "12rem",
      timerProgressBar: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => { },
      customClass: {
        htmlContainer: "text-sm",
      },
    });
  },

  loading_close() {
    Swal.close();
  },

  question(title, confirmButtonText = 'Si, eliminar') {
    // question(title, callback)
    return Swal.fire({
      html: title,
      icon: "question",
      width: "20rem",
      showDenyButton: true,
      confirmButtonText: confirmButtonText,
      denyButtonText: 'Cancelar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        console.log('isConfirmed');
        return true;
        /* if (callback && typeof callback === 'function') {
          callback(); // Llama a la función de callback si está definida y es una función
        } */
      } else if (result.isDenied) {
        console.log('isDenied');
        return false;
      }
    });
  },
};
