<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import Carousel from "./components/Carousel.vue";
// import CategoriesList from "./components/CategoriesList.vue";

/* import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png"; */

/* const sales = {
  us: {
    country: "United States",
    sales: 2500,
    value: "$230,900",
    bounce: "29.9%",
    flag: US,
  },
  germany: {
    country: "Germany",
    sales: "3.900",
    value: "$440,000",
    bounce: "40.22%",
    flag: DE,
  },
  britain: {
    country: "Great Britain",
    sales: "1.400",
    value: "$190,700",
    bounce: "23.44%",
    flag: GB,
  },
  brasil: {
    country: "Brasil",
    sales: "562",
    value: "$143,960",
    bounce: "32.14%",
    flag: BR,
  },
}; */
</script>
<template>
  <div class="py-4 mt-3 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Alumnos"
              :value="CountStudent"
              description="Registrados"
              :icon="{
                component: 'fas fa-user-graduate',
                background: 'bg-info',
                shape: 'rounded-circle shadow-none',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Depósitos"
              :value="depositos"
              description="Pendientes"
              :icon="{                
                component: 'fas fa-money-check',
                background: 'bg-secondary',
                shape: 'rounded-circle shadow-none',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Cursos"
              :value="CountCourse"
              description="Registrados"
              :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-success',
                shape: 'rounded-circle shadow-none',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Deuda"
              :value="Payment"
              description="Acumulada"
              :icon="{                
                component: 'fas fa-coins',
                background: 'bg-danger',
                shape: 'rounded-circle shadow-none',
              }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart              
              v-if="loading"
                ref="mychart"
                id="chart-line"
                title="Ingreso Mensual  "
                description="Enero - Diciembre"
                :chart="chart"                
              />
              <gradient-line-chart  
              v-if="!loading"            
                ref="mychart"
                id="chart-line"
                title="Ingreso Mensual  "
                description="Enero - Diciembre"
                :chart="chart"                
              />
            </div>
          </div>
          <div class="col-lg-5">
            <div v-if="loading" id="loading" class="loader">
              <span style="font-family: 'Source Sans 3', sans-serif !important;">{{ loadingText }}</span>
            </div>
            <carousel />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _Api from "@/services/StudentService.js";

export default {
  data() {
    return {
      data: "",
      loading: false,
      loadingText: 'Cargando...',
      CountStudent: '',
      CountCourse: '',
      depositos: '',
      Payment: '',
      chart: {
              labels: [
                'Ene',
                'Feb',
                'Mar',
                'Abr',
                'May',
                'Jun',
                'Jul',
                'Ago',
                'Set',
                'Oct',
                'Nov',
                'Dic',
              ],
              datasets: [
                {
                  label: 'Total: ',
                  data: [],
                },
              ],
            },
    };
  },

  methods: {
    async init_resources() {
      this.loading = true;
      try {
        const request = await _Api.get_data();
        const response = request.data;
        console.log(response);
        if (response.status == 200) {
          this.data = response.rows;
          this.CountStudent = this.data.CountStudent == 0 ? '0' : this.data.CountStudent;
          this.CountCourse = this.data.CountCourse == 0 ? '0' : this.data.CountCourse;
          this.depositos = this.data.register == 0 ? '0' : this.data.register;
          this.Payment = this.formatNumberInPEN(parseFloat(this.data.Payment).toFixed(2));

          let values = [];
          for(let i=0; i<this.data.char.length; i++) {
            values.push(this.data.char[i].SUMA_MES);
          }
          console.log(values);

          this.chart = {
                  labels: [
                    'Ene',
                    'Feb',
                    'Mar',
                    'Abr',
                    'May',
                    'Jun',
                    'Jul',
                    'Ago',
                    'Set',
                    'Oct',
                    'Nov',
                    'Dic',
                  ],
                  datasets: [
                    {
                      label: 'Total: ',
                      data: values,
                    },
                  ],
                };
          // this.$refs.mychart.refreshChart();
          this.loading = false;
        }

        if (response.status == 402) {
          console.log("Error 402", response.message);
          this.loading = false;
        }
      } catch (error) {
        console.log("error_save: " + error.message);
        this.loading = false;
      }
    },

    formatNumberInPEN(number) {
      const formatter = new Intl.NumberFormat('es-PE', {
        style: 'currency',
        currency: 'PEN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(number);
    }
  },

  mounted() {
    this.init_resources();
  },

  created() {
    //this.init_resources();
  },
};
</script>
