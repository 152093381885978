<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";
//import SidenavCard from "./SidenavCard.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const getRoute = () => {
    const route = useRoute();
    const routeArr = route.path.split("/");
    return routeArr[1];
};

const userRole = computed(() => {
    const role = localStorage.getItem("userRole");
    return role ? role.split(",") : []; // Convertir la cadena de roles en un array
});

const userHasRole = (roles) => {
    return roles.some((role) => userRole.value.includes(role));
};
</script>
<script>
export default {
    data() {
        return {
            isGoogle: false,
        };
    },
};
</script>
<template>
    <div v-if="!isGoogle" class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
        <ul class="navbar-nav">
            <li class="nav-item">
                <sidenav-item to="/dashboard" :class="getRoute() === 'dashboard' ? 'active' : ''"
                    :navText="isRTL ? 'لوحة القيادة' : 'Dashboard'">
                    <template v-slot:icon>
                        <i class="ni ni-tv-2 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>            
            <li class="nav-item">
                <sidenav-item to="/depositos/sincronizar" :class="getRoute() === 'depositos-sync' ? 'active' : ''"
                    :navText="isRTL ? 'الجداول' : 'Sincronizar'">
                    <template v-slot:icon>
                        <i class="far fa-calendar-check mb-1 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/depositos/gestionar" :class="getRoute() === 'depositos-gestionar' ? 'active' : ''"
                    :navText="isRTL ? 'الفواتیر' : 'Gestionar'">
                    <template v-slot:icon>
                        <i class="far fa-handshake text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/depositos/matricula" :class="getRoute() === 'depositos-matricula' ? 'active' : ''"
                    :navText="isRTL ? 'الفواتیر' : 'Matricula'">
                    <template v-slot:icon>
                        <i class="far fa-check-circle text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <!-- <li class="mt-3 nav-item">
                <h6 v-if="isRTL" class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    صفحات المرافق
                </h6>
                <h6 v-else class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    MAESTROS
                </h6>
            </li> -->
            <li class="nav-item">
                <sidenav-item to="/maestro/students" :class="getRoute() === '/maestro/students' ? 'active' : ''"
                    :navText="isRTL ? 'الواقع الافتراضي' : 'Alumnos Registrados'">
                    <template v-slot:icon>
                        <i class="far fa-id-card mb-1 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/maestro/programas" :class="getRoute() === 'programas' ? 'active' : ''"
                    :navText="isRTL ? 'الواقع الافتراضي' : 'Maestro Programas'">
                    <template v-slot:icon>
                        <i class=" far fa-clipboard mb-1 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/maestro/codigo-pago" :class="getRoute() === 'maestro/codigo-pago' ? 'active' : ''"
                    :navText="isRTL ? 'الواقع الافتراضي' : 'Maestro Codigo Pagos'">
                    <template v-slot:icon>
                        <i class="far fa-list-alt mb-1 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
           
            <!-- <li class="mt-3 nav-item">
                <h6 v-if="isRTL" class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    صفحات المرافق
                </h6>
                <h6 v-else class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    REPORTES
                </h6>
            </li> -->
            <li class="nav-item">
                <sidenav-item to="/reporte/sistema-ingresos"
                    :class="getRoute() === 'reporte-sistemaingresos' ? 'active' : ''"
                    :navText="isRTL ? 'حساب تعريفي' : 'Reporte Depósitos U.N.P.'">
                    <template v-slot:icon>
                        <i class="far fa-credit-card mb-1 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/reporte/reporte-deudas" :class="getRoute() === 'reporte-deudas' ? 'active' : ''"
                    :navText="isRTL ? 'حساب تعريفي' : 'Reporte Deudas'">
                    <template v-slot:icon>
                        <i class="far fa-calendar-times mb-1 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/reporte/alumno-status" :class="getRoute() === 'alumno-status' ? 'active' : ''"
                    :navText="isRTL ? 'حساب تعريفي' : 'Reporte Matricula'">
                    <template v-slot:icon>
                        <i class="far fa-calendar-times mb-1 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/reporte/reporte-alumnos" :class="getRoute() === 'reporte-alumnos' ? 'active' : ''"
                    :navText="isRTL ? 'الواقع الافتراضي' : 'Reporte Alumnos'">
                    <template v-slot:icon>
                        <i class="far fa-user mb-1 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <div v-if="userHasRole(['Sistemas', 'Administrador'])" class="nav-item sidebar-item">
                <hr class="mt-1 mb-1 horizontal dark" />

                <li>
                    <sidenav-item to="/settings/users" class="sidebar-item"
                        :class="getRoute() === 'users' ? 'active' : ''" :navText="isRTL ? 'تسجيل الدخول' : 'Usuarios'">
                        <template v-slot:icon>
                            <i class="far fa-user-circle mb-1 text-dark text-sm opacity-10"></i>
                        </template>
                    </sidenav-item>
                </li>
                <!-- <li>
                    <sidenav-item to="/settings/roles" class="sidebar-item"
                        :class="getRoute() === 'roles' ? 'active' : ''" :navText="isRTL ? 'اشتراك' : 'Rol'">
                        <template v-slot:icon>
                            <i class="far fa-edit text-dark text-sm opacity-10"></i>
                        </template>
                    </sidenav-item>
                </li> -->
            </div>

            <!-- <li class="nav-item">
        <sidenav-item
          to="/usuarios"
          :class="getRoute() === 'usuarios' ? 'active' : ''"
          :navText="isRTL ? 'تسجيل الدخول' : 'Usuarios'"
        >
          <template v-slot:icon>
            <i class="ni ni-circle-08 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/roles"
          :class="getRoute() === 'roles' ? 'active' : ''"
          :navText="isRTL ? 'اشتراك' : 'Rol'"
        >
          <template v-slot:icon>
            <i class="ni ni-ungroup text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->
        </ul>
    </div>

    <div v-if="isGoogle" class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
        <ul class="navbar-nav">
            <li class="nav-item">
                <sidenav-item to="/dashboard" :class="getRoute() === 'dashboard' ? 'active' : ''"
                    :navText="isRTL ? 'لوحة القيادة' : 'Dashboard'">
                    <template v-slot:icon>
                        <i class="ni ni-tv-2 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="mt-3 nav-item">
                <h6 v-if="isRTL" class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    صفحات المرافق
                </h6>
                <h6 v-else class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    OPCIONES
                </h6>
            </li>
            <li class="nav-item">
                <sidenav-item to="/alumno-reporte" :class="getRoute() === 'alumno-reporte' ? 'active' : ''"
                    :navText="isRTL ? 'الجداول' : 'Reportes'">
                    <template v-slot:icon>
                        <i class="ni ni-cloud-download-95 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/alumno/perfil" :class="getRoute() === 'alumno/perfil' ? 'active' : ''"
                    :navText="isRTL ? 'الفواتیر' : 'Mi Perfil'">
                    <template v-slot:icon>
                        <i class="ni ni-credit-card text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
        </ul>
    </div>
</template>
