export default {
    name: 'Reporte',
    component: () => import('@/modules/reports/layouts/ReportLayout.vue'),
    children: [
        {
            path: 'sistema-ingresos',
            name: 'Sistema Ingresos',
            component: () => import('@/modules/reports/views/EntrySystem.vue'),
        },
        {
            path: 'reporte-deudas',
            name: 'Reporte Deudas',
            component: () => import('@/modules/reports/views/DebtReport.vue'),
        },
        {
            path: 'reporte-alumnos',
            name: 'Reporte Alumnos',
            component: () => import('@/modules/reports/views/StudentReport.vue'),
        },
        {
            path: 'alumno-status',
            name: 'Reporte Alumno por Estado',
            component: () => import('@/modules/reports/views/StudentStatus.vue'),
        },
    ]
    
}