import ServiceAPI from '../utils/axios.js';

const url = '/auth';

export default {
    auth(params) {
        return ServiceAPI.post(`${url}/login`, params)
            .then((response) => {
                const { token, roles } = response.data;
                const expirationTime = new Date().getTime() + (response.data.expiresIn * 1000); // Tiempo de expiración del token
                localStorage.setItem('token', token); // Guarda el token en el almacenamiento local
                localStorage.setItem('userRole', roles); // Guarda el rol en el almacenamiento local
                localStorage.setItem('tokenExpiration', expirationTime); // Guarda el tiempo de expiración del token
                this.checkTokenExpiration();
                return response;
            });
    },

    authLogin(email) {
        localStorage.setItem('authentication', JSON.stringify({email: email}));

        return {email: email};
    },

    // logout() {
    //     localStorage.removeItem('token');        
    //     localStorage.removeItem('authentication');

    //     return ServiceAPI.post(`${url}/logout`, {});
    // }

    checkTokenExpiration() {
        const tokenExpiration = localStorage.getItem('tokenExpiration');
        if (tokenExpiration) {
            const currentTime = new Date().getTime();
            if (currentTime > parseInt(tokenExpiration)) {
                // Tiempo de expiración alcanzado, cerrar sesión automáticamente
                return this.logout();
            }
        }
        return Promise.resolve();
    },

    logout() {
        const url = '/auth/logout';
        return ServiceAPI.post(url, {})
            .then(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('authentication');
                localStorage.removeItem('userRole');
                localStorage.removeItem('tokenExpiration');
                return Promise.resolve();
            })
            .catch(error => {
                console.error('Error al cerrar sesión en el backend:', error);
                return Promise.reject(error);
            });
    }
    
}

