<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Bienvenido</h4>
                  <p class="mb-0">
                    Sistema de gestión de pagos
                    <b class="text-info text-gradient">POSGRADO</b>
                  </p>
                </div>
                <div class="card-body">
                  <div class="mb-3">
                    <argon-input
                      id="email"
                      :disabled="disabled"
                      :error="errorEmail"
                      v-model="email"
                      type="email"
                      placeholder="Email"
                      size="lg"
                      autocomplete="off"
                    />
                  </div>
                  <div class="mb-3">
                    <argon-input
                      id="password"
                      :disabled="disabled"
                      :error="errorPass"
                      v-model="password"
                      type="password"
                      placeholder="Contraseña"
                      size="lg"
                    />
                  </div>
                  <argon-switch id="rememberMe" name="remember-me"
                    >Remember me</argon-switch
                  >

                  <div class="text-center">
                    <argon-button
                      :disabled="disabled"
                      class="mt-4"
                      variant="gradient"
                      color="info"
                      fullWidth
                      @click="loginUser"
                      size="lg"
                    >
                      <img
                        v-if="disabled"
                        class="me-2"
                        src="/img/loading-gif.gif"
                        width="15"
                      />
                      Iniciar Sesión
                    </argon-button>
                  </div>

                  <div
                    v-if="errorMssg"
                    style="font-size: 14px"
                    class="text-danger p-3 text-center"
                  >
                    * Credenciales no registradas.
                  </div>
                </div>
                <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <a href="javascript:;" class="text-info text-gradient font-weight-bold">Sign up</a>
                  </p>
                </div> -->
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(./101.jpg);
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                "
              >
                <span class=""></span>
                <h4
                  class="mt-5 mb-0 font-weight-bolder text-dark position-relative"
                >
                  Escuela de POSGRADO
                </h4>
                <small class="mb-2 text-info text-gradient"
                  >Universidad Nacional de Piura</small
                >
                <!-- <p class="text-dark position-relative">
                  "Nuestra mayor debilidad reside en rendirnos. La forma más
                  segura de tener éxito es intentarlo una vez más". <br /><b
                    >Thomas A. Edison</b
                  >.
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import _Services from "../../services/AuthService.js";

export default {
  data() {
    return {
      email: "",
      password: "",
      disabled: false,
      errorEmail: false,
      errorPass: false,
      errorMssg: false,
    };
  },

  methods: {
    async loginUser() {
      this.errorMssg = false;
      this.errorEmail = this.email == "" ? true : false;
      this.errorPass = this.password == "" ? true : false;
      if (this.email == "" || this.password == "") return;

      const params = {
        email: this.email,
        password: this.password,
      };
      try {
        this.disabled = true;
        const request = await _Services.auth(params);
        const response = request.data;
        console.log(request);
        if (response.status == 200) {
          const responseAuth = await _Services.authLogin(this.email);
          console.log(responseAuth);
          // const email = JSON.parse(localStorage.getItem('authentication'));
          this.disabled = false;
          this.$router.replace("/dashboard");
        }
        if (response.status == 402) {
          console.log(response.error);
          this.errorMssg = true;
          this.disabled = false;
        }
      } catch (error) {
        console.log("error_login: " + error.message);
        this.disabled = false;
      }
    },
  },
};
</script>

<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
/* import ArgonAlert from "@/components/ArgonAlert.vue"; */

const body = document.getElementsByTagName("body")[0];

const store = useStore();
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});
</script>
